import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ebaylogo from "../../assets/images/ebaylogo.png";
import facebooklogo from "../../assets/images/facebook.png";
import depoplogo from "../../assets/images/depoplogo.png";
import SearchCard from "./SearchCard";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import CardPopup from "./CardPopup/CardPopup";
import { useNavigate } from "react-router-dom";
import RouteNames from "../../routes/RouteNames";
import {
  amplitudeLog,
  calculateMostProfitablePrice,
} from "../../utils/utilFunctions";
import { getResultsUpdatesApi, getResultsApi } from "../../api/dashboardApi";

const ResultPagination = ({
  newItemAdded,
  setNewItemAdded,
  setResultsReturned,
  data,
  setData,
  updateData,
  setUpdateData,
}) => {
  let history = useNavigate();
  const [cardPopup, setCardPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [cardData, setCardData] = useState({});
  const [updateResults, setUpdateResults] = useState(false);
  const [createTimer, setCreateTimer] = useState(true);
  const [loader, setLoader] = useState(true);
  const [refreshAPI, setRefreshAPI] = useState(false);
  const intervelTime = 5000;

  const itemsPerPage = 18;

  const fetchData = async () => {
    const response = await getResultsApi(currentPage, itemsPerPage);
    if (response.success) {
      setData(response.data.results);
      setTotalItems(response.data.count);
      setLoader(false);
      if (response.data.count === 0) setResultsReturned(false);
      else setResultsReturned(true);
      setUpdateResults(false);
      setUpdateData(false);
    }
  };
  let interval;

  const getLandingPageTime = async () => {
    let response = {};
    let lastTimeRequest = new Date(Date.now() - 200000);
    response = await getResultsUpdatesApi(lastTimeRequest);
    if (response.success) {
      if (response.data.updatedResult === true) {
        setUpdateResults(true);
        setCreateTimer(false);
      }
      if (response.data.anyPendingItem === false) {
        stopTimer();
      }
    }
  };

  const stopTimer = () => {
    clearInterval(interval);
  };

  useEffect(() => {
    interval = setInterval(getLandingPageTime, intervelTime);
    return () => {
      clearInterval(interval);
    };
  }, [createTimer]);
  useEffect(() => {
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    if (updateData) {
      fetchData();
    }
  }, [updateData]);
  const refreshData = () => {
    if (currentPage === 1) fetchData();
    else setCurrentPage(1);
  };
  useEffect(() => {
    setTimeout(() => {
      fetchData();
    }, 3000);
  }, [newItemAdded]);
  useEffect(() => {
    if (newItemAdded) {
      setCreateTimer(!createTimer);
      refreshData();
      setNewItemAdded(false);
    }
  }, [newItemAdded]);

  useEffect(() => {
    if (updateResults) refreshData();
  }, [updateResults]);
  useEffect(() => {
    if (refreshAPI) {
      refreshData();
      setRefreshAPI(false);
    }
  }, [refreshAPI]);

  const handleNextbtn = () => {
    amplitudeLog("Product search", "Show next page results");
    setCurrentPage(currentPage + 1);
  };

  const handlePrevbtn = () => {
    amplitudeLog("Product search", "Show previous page results");
    setCurrentPage(currentPage - 1);
  };

  const displayCardDetails = (cardDetails) => {
    amplitudeLog(
      "Product search",
      "Selecting to view results",
      "Clicking on the card"
    );
    setCardData(cardDetails);
    history(`${RouteNames.UserSearchResult.route}/${cardDetails.id}`, {
      state: { from: RouteNames.Dashboard.route },
    });
    setCardPopup(true);
  };

  const onCloseResultCard = () => {
    document.getElementsByTagName("body")[0].classList.remove("noScroll");
    setCardPopup(false);
  };

  const onDisputeUpdate = (itemId, status) => {
    setData(
      data.map((item) => {
        return {
          ...item,
          disputeStatus: item.id === itemId ? status : item.disputeStatus,
        };
      })
    );
    if (cardPopup && cardData && cardData.id === itemId) {
      setCardData({ ...cardData, disputeStatus: status });
    }
  };

  const havebestMatchprice = (item) =>
    item.marketPlaceItems.every((el) => el.meanPrice == null) &&
    item.marketPlaceItems.some((el) => el.havebestMatchprice !== null);
  const renderData = () => {
    return (
      <>
        {loader ? (
          <h1>Loading...</h1>
        ) : data.length > 0 ? (
          data.map((item, index) => {
            const mostProfitable = calculateMostProfitablePrice(item);

            const title =
              item.barcode == null || item.title !== null
                ? item.title
                : item.barcode;
            return (
              <SearchCard
                key={index}
                itemId={item.id}
                title={title}
                dateSubmitted={item.createdAt}
                dispute={item.disputeStatus}
                uniqueCode={item.uniqueCode}
                condition={item.condition.name}
                marketPlaceItems={item.marketPlaceItems}
                setRefreshAPI={setRefreshAPI}
                mostProfitablePrice={
                  item.status === 2 && mostProfitable
                    ? mostProfitable.meanPrice.toFixed(2) || ""
                    : ""
                }
                havebestMatchprice={havebestMatchprice(item)}
                logo={
                  item.status === 2 && mostProfitable
                    ? mostProfitable.marketPlaceName === "Ebay" ||
                      mostProfitable.marketPlaceName ===
                        "Ebay Edinburg University"
                      ? ebaylogo
                      : mostProfitable.marketPlaceName === "Facebook"
                      ? facebooklogo
                      : depoplogo
                    : ""
                }
                status={item.status}
                {...((item.status === 2 || item.status === 4) &&
                  !havebestMatchprice(item) && {
                    onClick: () => displayCardDetails(item),
                  })}
              />
            );
          })
        ) : (
          <p className="noSearchResults">
            To get started, please enter your item’s description in the field
            above.
          </p>
        )}
      </>
    );
  };

  return (
    <>
      {renderData()}
      {data.length > 0 ? (
        <div className="searchCardPagination">
          <span className="numberOfPages">
            {" "}
            {currentPage * itemsPerPage + 1 - itemsPerPage} -{" "}
            {totalItems > currentPage * itemsPerPage
              ? currentPage * itemsPerPage
              : totalItems}{" "}
            <i>of {totalItems}</i>{" "}
          </span>
          <span className="PaginationButtons">
            <button
              onClick={handlePrevbtn}
              disabled={totalItems === 0 || currentPage === 1}
            >
              <FaChevronLeft />
            </button>
            <button
              onClick={handleNextbtn}
              disabled={
                totalItems === 0 ||
                Math.ceil(totalItems / itemsPerPage) === currentPage
              }
            >
              <FaChevronRight />
            </button>
          </span>
        </div>
      ) : (
        ""
      )}
      {cardPopup && (
        <CardPopup
          onClose={onCloseResultCard}
          onDisputeUpdate={onDisputeUpdate}
          popShow={cardPopup}
          itemId={cardData.id}
          title={cardData.title}
          barcode={cardData.barcode}
          condition={cardData.condition}
          disputeStatus={cardData.disputeStatus}
          marketPlaceItems={cardData.marketPlaceItems}
          itemImages={cardData.itemImages}
          uniqueCode={cardData.uniqueCode}
        />
      )}
    </>
  );
};
ResultPagination.propTypes = {
  newItemAdded: PropTypes.bool.isRequired,
  setNewItemAdded: PropTypes.func.isRequired,
  setResultsReturned: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  updateData: PropTypes.bool.isRequired,
  setUpdateData: PropTypes.func.isRequired,
};
export default ResultPagination;
