import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./adminNav.scss";
function AdminNav(props) {
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <div className="dashboard__navigation">
      <NavLink to="/admin/results" className="dashboard__navigation_menu_item">
        Search results
      </NavLink>
      <NavLink to="/dashboard" className="dashboard__navigation_menu_item">
        Submit Item
      </NavLink>
      <NavLink
        to="/admin/testFilter"
        className="dashboard__navigation_menu_item"
      >
        Test filters
      </NavLink>
      <NavLink
        to="/admin/badWordStats"
        className="dashboard__navigation_menu_item"
      >
        Bad Word Stats
      </NavLink>
      <NavLink
        to="/admin/marketPlaces"
        className="dashboard__navigation_menu_item"
      >
        Marketplaces
      </NavLink>
      <a
        href="#"
        onClick={() => setShowDropdown((showDropdown1) => !showDropdown1)}
      >
        More
      </a>
      {showDropdown && (
        <div className="dashboard__nav--dropdown">
          <NavLink to="/admin/wordAnchorBank">Word Bank</NavLink>
          <NavLink to="/admin/apiFilterStats">Filter Stats</NavLink>
          <NavLink to="/admin/bulkUpload">Bulk Upload</NavLink>

          <NavLink to="/admin/luminatiErrors">
            Luminati
            <br />
            Errors
          </NavLink>
          <NavLink to="/admin/disputedItems">Disputed Items</NavLink>
          <NavLink to="/admin/conditions">Conditions</NavLink>
          <NavLink to="/admin/pairs">Pairs</NavLink>

          <NavLink to="/admin/apistats">Stats</NavLink>
          <NavLink to="/admin/unassignedKeyWords">unassigned key words</NavLink>
          <NavLink to="/admin/postageSettings">ROYAL MAIL PRICES</NavLink>
          <NavLink to="/admin/organization">Organization</NavLink>
          <NavLink to="/admin/stores">Stores</NavLink>
        </div>
      )}
    </div>
  );
}

export default AdminNav;
